import React from 'react'

const BobaFacts = () => {
  const facts = require(`./factsList`).factList;
  const factTime = 5;
  const animationLength = facts.length * factTime;

  return (
    <section className="BobaFacts">
      <style dangerouslySetInnerHTML={{
        __html: 
          [`.BobaFacts__Container .BobaFacts__Fact{animation: fade-facts ease ${animationLength}s infinite;}`,
          `@keyframes fade-facts {${22/facts.length}%{opacity:1;} ${100/facts.length}%{opacity:1;} ${122/facts.length}%{opacity:0;}} `].concat(
            facts.map((fact, i) => `.BobaFacts__Container .BobaFacts__Fact:nth-child(${i + 1}){animation-delay:${i * factTime}s;}`)).join('\n')
      }}></style>
      <div className="BobaFacts__Container">
        {facts.map((fact, index) => 
          <blockquote className="BobaFacts__Fact" key={index}>{fact}</blockquote>
        )}
      </div>
      <div className="BobaFacts__SpacingContainer" style={{width: facts.length * 100 + '%'}}>
        {facts.map((fact, index) => 
          <blockquote className="BobaFacts__SpacingFact" style={{width: 100/facts.length + '%'}} key={index}>{fact}</blockquote>
        )}
      </div>
    </section>
  )
}


export default BobaFacts
