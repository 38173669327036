import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link, StaticQuery, graphql } from 'gatsby';

import { Navigation, SocialIcons } from '.';

// Styles
import '../../styles/main.scss';

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass }) => {
  const site = data.allGhostSettings.edges[0].node

  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <body className={bodyClass} />
        <link rel="stylesheet" href="https://use.typekit.net/kio7xkk.css" />
      </Helmet>

      <div className="Layout__ContentContainer">
        {/* The main header section on top of the screen */}
        <header className="Header">
          <Link to="/">
            {site.logo ?
              <img className="Header__Logo" src={site.logo} alt={site.title} />
              : <span className="Header__Title">{site.title}</span>
            }
          </Link>
          <nav className="Header__NavigationContainer" id="navigation-container">
            <div className="Header__NavigationItemsContainer">
              {/* The navigation items as setup in Ghost */}
              <Navigation data={site.navigation} filterSocialLinks/>
            </div>
            <SocialIcons data={site.navigation}/>
          </nav>
        </header>

        <main className="Layout__Main">
          {/* All the main content gets inserted here, index.js, post.js */}
          {children}
        </main>
        {/* The footer at the very bottom of the screen */}
        <footer className="Layout__PageFooter">
          © 2019 {site.title}
        </footer>
      </div>

    </>
  )
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  data: PropTypes.shape({
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
};

const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
            query GhostSettings {
              allGhostSettings {
                edges {
                  node {
                    ...GhostSettingsFields
                  }
                }
              }
            }
        `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
);

export default DefaultLayoutSettingsQuery;
