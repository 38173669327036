import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import PostFooter from './PostFooter'

const Content = ({ content, isOnHome, hideDate, loading }) => {
  const url = `/${content.slug}/`

  return (<article className={isOnHome ? "Content" : "Content Content--NotHome"}>
    {isOnHome ?
      <Link to={url} className="Content__Title Content__Title--Link">
        <h1>{content.title}</h1>
      </Link>
      : <h1 className="Content__Title">{content.title}</h1>
    }
    {content.custom_excerpt ?
      <p className="Content__CustomExcerpt">{content.custom_excerpt}</p> 
      : null}
    {content.local_feature_image &&
      <Img fluid={content.local_feature_image.childImageSharp.fluid} loading={loading} durationFadeIn={200} className={isOnHome ? "Content__FeatureImage Content__FeatureImage--Home" : "Content__FeatureImage Content__FeatureImage--Large"} alt={content.title} />
    }
    {!hideDate && <span className="Content__Date">{content.published_at_pretty}</span>}
    {/* The main post content */}
    <section
      className="Content__Content load-external-scripts"
      dangerouslySetInnerHTML={{ __html: content.html }}
    />
    <PostFooter/>
  </article>)
}

Content.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
    local_feature_image: PropTypes.object,
    published_at_pretty: PropTypes.string.isRequired,
    custom_excerpt: PropTypes.string
  }).isRequired,
  isOnHome: PropTypes.bool,
  hideDate: PropTypes.bool,
  loading: PropTypes.string
}

Content.defaultProps = {
  isOnHome: false,
  hideDate: false,
  loading: 'eager'
}

export default Content
