import React from 'react'
import PropTypes from 'prop-types'

import { getSocialIcon, isSocialLink } from './social'

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const SocialIcons = ({ data, iconClass }) => (
  <>
    {data.map((navItem, i) => {
      if ( isSocialLink(navItem.url)) {
        return <a className={iconClass} href={navItem.url} key={i} target="_blank" rel="noopener noreferrer" title={navItem.label}>
                  { getSocialIcon(navItem.url, 'SocialIcon__Image') }
                </a>
      }
    })}
  </>
)

SocialIcons.defaultProps = {
  iconClass: `SocialIcon--Default`
}

SocialIcons.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  iconClass: PropTypes.string
}

export default SocialIcons
