import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

  return (
    <nav className="Pagination" role="navigation">
      {previousPagePath && (
        <Link to={previousPagePath} className="Pagination__Previous" rel="prev">
          Previous
        </Link>
      )}
      {numberOfPages > 1 && <div className="Pagination__LocationContainer">
        <span>Page {humanPageNumber} of {numberOfPages}</span></div>}
      {nextPagePath && (
        <Link to={nextPagePath} className="Pagination__Next" rel="next">
          Next
        </Link>
      )}
    </nav>
  )
}

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Pagination
