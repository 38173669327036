import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { isSocialLink } from './social'
import url from 'url';

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const Navigation = ({ data, navClass, filterSocialLinks }) => (
  <>
    {data.map((navItem, i) => {
      if (!filterSocialLinks || !isSocialLink(navItem.url)) {
        const path = url.parse(navItem.url).path;
        return <Link className={navClass} to={path} key={i}>{navItem.label}</Link>
      }
    })}
  </>
)

Navigation.defaultProps = {
  navClass: `Navigation__Item`,
  filterSocialLinks: false
}

Navigation.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  navClass: PropTypes.string,
  filterSocialLinks: PropTypes.bool
}

export default Navigation
