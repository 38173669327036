import React from 'react'

import Facebook from "../../svg/icons/facebook.inline.svg"
import Instagram from "../../svg/icons/instagram.inline.svg"
import Twitter from "../../svg/icons/twitter.inline.svg"

const socialLinks = {
  'https://www.facebook.com': 'facebook',
  'https://www.instagram.com': 'instagram',
  'https://www.twitter.com': 'twitter'
}

const getSocialIcon = (navigationLink, iconClass) => {
  let socialType = 'none'
  for (let link in socialLinks) {
    if (navigationLink.toLowerCase().startsWith(link)) {
      socialType = socialLinks[link];
    }
  }

  switch (socialType) {
    case 'facebook':
      return <Facebook className={iconClass} />
    case 'instagram':
      return <Instagram className={iconClass} />
    case 'twitter':
      return <Twitter className={iconClass} />
    default:
  }
}

const isSocialLink = (navigationLink) => {
  for (let link in socialLinks) {
    if (navigationLink.toLowerCase().startsWith(link)) {
      return true
    }
  }
  return false
}

export { getSocialIcon, isSocialLink }
