import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const GuidePreview = ({ post, large }) => {
  const url = `/${post.slug}/`

  return (
    <article className={"GuidePreview" + (large ? " GuidePreview--Large" : " GuidePreview--Small")}>
      {post.feature_image &&
        <Link to={url} className={"GuidePreview__ImageLinkContainer" + (large ? " GuidePreview__ImageLinkContainer--Large" : " GuidePreview__ImageLinkContainer--Small")} >
          <Img fluid={post.local_feature_image.childImageSharp.fluid} loading="eager" durationFadeIn={200} className={"GuidePreview__Image" + (large ? " GuidePreview__Image--Large" : " GuidePreview__Image--Small")} alt={post.title} />
        </Link>
      }
      <div className="GuidePreview__ContentContainer">
        <header className="GuidePreview__Header">
          <Link to={url}><h2 className={"GuidePreview__Title" + (large ? "" : " GuidePreview__Title--Small")}>{post.title}</h2></Link>
        </header>
        <p className={"GuidePreview__Excerpt" + (large ? "" : " GuidePreview__Excerpt--Small")}>{post.excerpt}</p>
        <Link className={"GuidePreview__ReadMoreButton" + (large ? "": " GuidePreview__ReadMoreButton--Small")} to={url}>Read more</Link>
      </div>
    </article>
  )
}

GuidePreview.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    local_feature_image: PropTypes.object,
    excerpt: PropTypes.string.isRequired
  }).isRequired,
  large: PropTypes.bool
}

GuidePreview.defaultProp = {
  large: false
}

export default GuidePreview
