import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const PostPreview = ({ post, imageOnRight, loading }) => {
  const url = `/${post.slug}/`

  return (
    <article className="PostPreview">
      {post.feature_image &&
        <Link to={url} className={"PostPreview__ImageLinkContainer" + (imageOnRight ? " PostPreview__ImageLinkContainer--ImageOnRight" : "")} >
          <Img fluid={post.local_feature_image.childImageSharp.fluid} loading={loading} durationFadeIn={200} className="PostPreview__Image" alt={post.title} />
        </Link>
      }
      <div className="PostPreview__ContentContainer">
        <header className="PostPreview__Header">
          <Link to={url}><h2 className="PostPreview__Title">{post.title}</h2></Link>
          <span className="PostPreview__Date">{post.published_at_pretty}</span>
        </header>
        <p className="PostPreview__Excerpt">{post.excerpt}</p>
        <button className="PostPreview__ReadMoreButton">
          <Link to={url}>Read more</Link>
        </button>
      </div>
    </article>
  )
}

PostPreview.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    local_feature_image: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    excerpt: PropTypes.string.isRequired,
    published_at_pretty: PropTypes.string.isRequired
  }).isRequired,
  imageOnRight: PropTypes.bool,
  loading: PropTypes.string
}

PostPreview.defaultProp = {
  imageOnRight: false,
  loading: 'lazy'
}

export default PostPreview
