import React from 'react'
import PropTypes from 'prop-types'

const SectionHeader = ({ sectionTitle }) => (
  <div className="SectionHeader">
    <div className="SectionHeader__HorizontalLineLeft"></div>
    <h4 className="SectionHeader__Title">{sectionTitle}</h4>
    <div className="SectionHeader__HorizontalLineRight"></div>
  </div>
)

SectionHeader.propTypes = {
  sectionTitle: PropTypes.string
}

export default SectionHeader
